.App-container {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

html, body { 
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

body  {
  padding-bottom: 60px;
}

@media (min-width: 300px) and (max-width: 600px) {
  h1 {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}