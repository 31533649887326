.Home {
    margin: 0 auto;
    height: 89vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.carousel-img {
    max-height: 78vh;
    max-width: 100vw;
    width: 100%;
}