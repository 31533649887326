.Footer {
    height: 65px;
    width: 100%;
}

.footer-nav {
    background-color: white;
    border: 1px solid #E2E2E2;
}

p {
    font-size: 1em;
    padding: 0;
    margin: 0;
    font-weight: 500;
}

.social-media-link {
    width: 30px;
    margin: 3px;
}