.Nav {
    min-height: auto;
}

@media screen and (max-width: 789px) {
    .desktop-nav {
        display: none;
    }
}

@media screen and (min-width: 789px) {
    .mobile-nav {
        display: none;
    }
}
